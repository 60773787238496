import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import { NewsCard } from "../components/NewsCard"
import SEO from "../components/SEO"

function NewsCategoryTemplate({ pageContext: { data } }) {
  const {
    strapiNewsPage: { readMoreText },
  } = useStaticQuery(graphql`
    {
      strapiNewsPage {
        readMoreText
      }
    }
  `)
  return (
    <Layout showNewsletterCTA>
      <SEO
        title={data.seo.title}
        description={data.seo.description}
        keywords={data.seo.keywords}
        jsonLDSchema={data.seo.jsonLDSchema}
        pathname={`${data.slug}`}
      />
      <section className="section is-medium" id="news-title">
        <div className="container">
          <div className="columns">
            <div className="column is-7">
              <h1 className="title is-1">{data.title}</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns is-multiline">
            {data.news?.map((n, i) => (
              <div className="column is-6 is-4-widescreen is-flex" key={n.id}>
                <NewsCard
                  categorySlug={data.slug}
                  news={n}
                  readMoreText={readMoreText}
                  isExternalNews={n.isExternalNews}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NewsCategoryTemplate
